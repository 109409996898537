import { defineStore } from "pinia";

export const useAppStore = defineStore("appStore", {
  state: () => ({
    isPopLogin: false,// 登录弹窗
    isLogin: false,// 登录状态
    isRegistry: false,// 注册状态
    userInfo: null, //用户信息存储
  }),
  getters: {
    getUserInfo: (state) => {
      return state.userInfo;
    }
  },
  actions: {
    setLoginShowStatus(state:boolean) {
      this.isPopLogin = state;
      this.isRegistry = false;
    },
    setRegShowStatus(state:boolean) {
      this.isPopLogin = state;
      this.isRegistry = state;
    },
    setLoginStatus(state: boolean) {
      this.isLogin = state;
    },
    setUserInfo(userInfo: any) {
      if(userInfo){
        this.userInfo = userInfo;
        storeUtils.setOpenId(userInfo.openId);
        storeUtils.setToken(userInfo.token);
      }
    },
  }
})
