<template>
  <div v-if="store.isPopLogin" class="login-container">
    <div id="above-content" class="above-content">
      <div class="d-none d-lg-block modal-use-left-section">
        <img src="@/assets/imgs/login-left.png" />
      </div>
      <div class=" modal-use-right-section" v-if="forgotPassword==false&&bindEmail==false">
        <SwitchPhoneMail class="d-none d-lg-block login-cut" @mouseenter="showTip = true" @mouseleave="showTip = false" v-if="currentLoginType == 'wxLogin'" @click="changeWay" ></SwitchPhoneMail>
        <SwitchWechat class="d-none d-lg-block login-cut" @mouseenter="showTip = true" @mouseleave="showTip = false" v-if="currentLoginType != 'wxLogin'" @click="changeWay" ></SwitchWechat>
        <div v-if="showTip && currentLoginType != 'wxLogin'" class="tip-box">微信扫码登录</div>
        <div v-if="showTip && currentLoginType == 'wxLogin'" class="tip-box">邮箱登录</div>
        <div class="close-login" @click="handleClose">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            class="close-circle"
          >
            <rect width="32" height="32" rx="16" fill="black" fill-opacity="0.2"></rect>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.07684 9.63801C9.34632 9.36852 9.78324 9.36852 10.0527 9.63801L15.9081 15.4933L21.7634 9.63801C22.0329 9.36852 22.4698 9.36852 22.7393 9.63801C23.0088 9.90749 23.0088 10.3444 22.7393 10.6139L16.884 16.4692L22.7393 22.3246C23.0088 22.5941 23.0088 23.031 22.7393 23.3005C22.4698 23.57 22.0329 23.57 21.7634 23.3005L15.9081 17.4451L10.0527 23.3005C9.78324 23.57 9.34632 23.57 9.07684 23.3005C8.80735 23.031 8.80735 22.5941 9.07684 22.3246L14.9322 16.4692L9.07684 10.6139C8.80735 10.3444 8.80735 9.90749 9.07684 9.63801Z"
              fill="white"
            ></path>
          </svg>
        </div>
        <div class="login-header">
          <div class="header-text" v-if="currentLoginType != 'wxLogin'" >登录智道账号</div>
          <div class="header-text" v-if="currentLoginType == 'wxLogin'" style="justify-content: center;">微信安全登录</div>
          <div class="wechat-des" v-if="currentLoginType != 'wxLogin'">未注册邮箱登录时将自动创建智道账号</div>
          <div class="wechat-des" v-if="currentLoginType == 'wxLogin'" style="justify-content: center;">未注册邮箱登录时将自动创建智道账号</div>
        </div>
        <div class="login-content" v-if="currentLoginType != 'wxLogin'">
          <div class="login-switch">
            <button
                class="login-btn"
                @click="changeWay2"
                :style="{ backgroundColor: currentLoginType == 'verify' ? '#ffffff' : 'rgb(238,238,238)' ,color: currentLoginType == 'verify' ? '#1b1b1c' : '#606266' }"
            >
              验证码登录
            </button>
            <button
                class="login-btn"
                @click="changeWay2"
                :style="{ backgroundColor: currentLoginType == 'password' ? '#ffffff' : 'rgb(238,238,238)',color: currentLoginType == 'password' ? '#1b1b1c' : '#606266'}"
            >
              密码登录
            </button>
          </div>
          <div class="inputContanier signup">
            <!-- 验证码登录 -->
            <el-form  class="login-rule-form" v-if="currentLoginType == 'verify'">
              <el-form-item prop="username" class="reform-box" @click="handleInput">
                <div class="entry-input emailPhone">
                  <el-input
                    size="large"
                    autofocus
                    v-model="emailNum"
                    placeholder="请输入邮箱"
                  />
                </div>
              </el-form-item>
              <p class="error-message" v-if="!isValidEmail">请输入正确的邮箱</p>
              <el-form-item class="reform-box" v-if="currentLoginType == 'verify'">
                <div class="entry-input password">
                  <el-input
                    class="verify-input"
                    size="large"
                    v-model="verificationCode"
                    placeholder="请输入六位数验证码"
                  >
                  </el-input>
                  <button
                      class="get-code-btn"
                      @click="getVerifyCode"
                      :disabled="isLoading"
                  >
                    {{ isLoading && countDownTime!==0 ? `${countDownTime}s` : '获取验证码' }}
                  </button>
                </div>
                <span class="mail-address" @click="routeMailAdress" v-if="isLoading">
                    <svg class="hand-icon pointing-hand" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16 11.5v1a2 2 0 0 1-2 2m0 0h-1m1 0a1 1 0 0 1 1 1a2 2 0 0 1-2 2m0 0h-1m1 0c.524 0 .922.47.836.987l-.058.342a2 2 0 0 1-1.972 1.671h-1.473c-2.173 0-3.26 0-4.125-.331c-.502-.192-1.142-.699-1.604-1.104a2.2 2.2 0 0 0-1.44-.565H2m12-7h6.5a1.5 1.5 0 0 0 0-3h-9.962l1.625-1.62a1.683 1.683 0 0 0-.14-2.51a1.696 1.696 0 0 0-2.072-.033l-4.598 3.6C4.473 8.626 3.117 9 2 9" color="currentColor"/></svg>
                    点击查看验证码
                  </span>
              </el-form-item>
              <p class="error-message" v-if="!errorVerificationCode">请输入正确的验证码</p>
              <el-form-item style="margin-bottom: 12px">
                <div class="entry-input password">
                  <el-button type="primary" size="large" class="btn-login" @click="handleEmailLogin"
                  >立即登录/注册</el-button
                  >
                </div>
              </el-form-item>
            </el-form>
            <!-- 验证码登录 -->
            <!-- 密码登录 -->
            <el-form  class="login-rule-form" v-if="currentLoginType == 'password'">
              <el-form-item prop="username" class="reform-box">
                <div class="entry-input emailPhone">
                  <el-input
                      size="large"
                      autofocus
                      v-model="username"
                      placeholder="请输入用户名"
                  />
                </div>
              </el-form-item>
              <p class="error-message" v-if="!isValidEmail">请输入正确的用户名和密码</p>
              <el-form-item prop="password" class="reform-box" v-if="currentLoginType == 'password'">
                <div class="entry-input password">
                  <el-input
                      class="verify-input"
                      size="large"
                      v-model="codeNum"
                      type="password"
                      show-password
                      autocomplete="off"
                      placeholder="请输入密码"
                  >
                  </el-input>
                </div>
              </el-form-item>
              <p class="error-message" v-if="!errorCodeNum">请输入正确的密码</p>
              <el-form-item style="margin-bottom: 12px">
                <div class="entry-input password">
                  <el-button type="primary" size="large" class="btn-login" @click="handleCodeLogin"
                    >立即登录/注册</el-button
                  >
                </div>
              </el-form-item>
            </el-form>
            <!-- 密码登录 -->
          </div>
          <div class="agreement-group">
            <input type="checkbox" v-model="isAgree" class="agree-checkbox">
            <span>我已阅读并同意</span>
            <a href="/#/agreement" target="_blank" class="link-style">使用协议</a>
            <span>及</span>
            <a href="/#/policy" target="_blank"  class="link-style">隐私政策</a>
            <span v-if="currentLoginType == 'password'" class="forgot_password" @click="forgotPassword = true" >忘记密码</span>
          </div>
        </div>
        <div class="login-content" style="margin-top: 20px ;" v-if="currentLoginType == 'wxLogin'">
          <img  v-if="wxLoginUrl"  class="wxLogin-box" :src="wxLoginUrl">
          <div v-else class="wxLogin-box"> </div>
          <div class="agreement-group" style="margin-left: 50px;">
            <span>扫码表示同意</span>
            <a href="/#/agreement" target="_blank" class="link-style">使用协议</a>
            <span>及</span>
            <a href="/#/policy" target="_blank"  class="link-style">隐私政策</a>
          </div>
        </div>
        <div class="d-none d-lg-block footer">
          <div class="signway-container">
            <div class="line-group">
              <span class="line"></span>
              <span class="divider">其他登录方式</span>
              <span class="line"></span>
            </div>
            <div class="login-way" >
              <div class="login-way-icon wechat" v-if="currentLoginType != 'wxLogin'"  @click="changeWay">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="svg__StyledSvg-sc-12zkjmv-0 bYmylE svg-icon ext00/login/wechat/2312 icon"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  style="width: 24px"
                >
                  <path
                    fill="#00C250"
                    fill-rule="evenodd"
                    d="M15.67 9.53c3.2 0 5.8 2.12 5.8 4.72 0 1.48-.83 2.8-2.13 3.66l.33 1.65-1.78-.94c-.68.23-1.43.36-2.22.36-3.2 0-5.78-2.12-5.78-4.73 0-2.6 2.59-4.72 5.78-4.72ZM9.94 4.56c3.18 0 5.86 1.81 6.68 4.28-.31-.04-.63-.06-.95-.06-3.6 0-6.55 2.42-6.55 5.47 0 .7.15 1.38.44 2a8.01 8.01 0 0 1-2.25-.43L5.2 16.93l.39-1.97A5.55 5.55 0 0 1 3 10.4c0-3.23 3.1-5.85 6.94-5.85Zm3.69 7.21a.84.84 0 0 0-.85.83c0 .46.38.83.85.83s.85-.37.85-.83a.84.84 0 0 0-.85-.83Zm4.09 0a.84.84 0 0 0-.85.83c0 .46.38.83.85.83s.85-.37.85-.83a.84.84 0 0 0-.85-.83ZM7.44 7.39a.94.94 0 0 0-.95.92c0 .51.43.93.95.93s.95-.42.95-.93a.94.94 0 0 0-.95-.92Zm4.99 0a.94.94 0 0 0-.95.92c0 .51.43.93.95.93s.95-.42.95-.93a.94.94 0 0 0-.95-.92Z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <div class="login-way-icon wechat" v-if="currentLoginType == 'wxLogin'" @click="changeWay">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="svg__StyledSvg-sc-12zkjmv-0 bYmylE svg-icon ext00/login/way/mail icon"
                    viewBox="0 0 28 28"
                    aria-hidden="true"
                    style="width: 28px"
                >
                  <g clip-path="url(#clip0_2060_3871)">
                    <path d="M20.9249 7.40479H7.0749C6.3349 7.40479 5.7749 7.96479 5.7749 8.70479V19.3048C5.7749 20.0448 6.3349 20.6048 7.0749 20.6048H20.9349C21.6749 20.6048 22.2349 20.0448 22.2349 19.3048V8.70479C22.2349 7.98479 21.6549 7.40479 20.9349 7.40479H20.9249ZM14.2749 14.1548C14.1749 14.2548 14.0749 14.2548 14.0449 14.2548C13.9549 14.2548 13.8749 14.2548 13.8149 14.1548L8.8449 9.18479H19.2449L14.2749 14.1548ZM20.4049 10.5148V18.8548H7.5549V10.4748L12.5249 15.4448C12.9249 15.8448 13.4649 16.0748 14.0049 16.0748C14.5849 16.0748 15.1249 15.8448 15.4849 15.4448L20.4149 10.5248L20.4049 10.5148Z"
                          fill="#414142">

                    </path>
                  </g>
                  <defs>
                    <clipPath id="clip0_2060_3871"><rect width="16.45" height="13.19" fill="white" transform="translate(5.7749 7.40479)"></rect></clipPath></defs>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 忘记密码和绑定邮箱 -->
      <div class="modal-use-right-section" v-if="forgotPassword==true || bindEmail==true">
        <div class="close-login" @click="handleClose">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              class="close-circle"
          >
            <rect width="32" height="32" rx="16" fill="black" fill-opacity="0.2"></rect>
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.07684 9.63801C9.34632 9.36852 9.78324 9.36852 10.0527 9.63801L15.9081 15.4933L21.7634 9.63801C22.0329 9.36852 22.4698 9.36852 22.7393 9.63801C23.0088 9.90749 23.0088 10.3444 22.7393 10.6139L16.884 16.4692L22.7393 22.3246C23.0088 22.5941 23.0088 23.031 22.7393 23.3005C22.4698 23.57 22.0329 23.57 21.7634 23.3005L15.9081 17.4451L10.0527 23.3005C9.78324 23.57 9.34632 23.57 9.07684 23.3005C8.80735 23.031 8.80735 22.5941 9.07684 22.3246L14.9322 16.4692L9.07684 10.6139C8.80735 10.3444 8.80735 9.90749 9.07684 9.63801Z"
                fill="white"
            ></path>
          </svg>
        </div>
        <div class="login-header">
          <div class="back-icon" @click="goBack">
            <svg xmlns="http://www.w3.org/2000/svg" class="svg__StyledSvg-sc-12zkjmv-0 bYmylE svg-icon ext00/login/header/back icon" viewBox="0 0 32 32" aria-hidden="true">
              <mask id="mask0_2056_26585" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
                <path d="M32 0H0V32H32V0Z" fill="white"></path>
              </mask>
              <g mask="url(#mask0_2056_26585)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0501 15.9899L19.5301 10.5199C19.8201 10.2199 19.8201 9.74986 19.5301 9.45986C19.2301 9.15986 18.7601 9.15986 18.4701 9.45986L12.5201 15.3999L12.4601 15.4699C12.3801 15.5399 12.3201 15.6199 12.2901 15.7099C12.2001 15.9299 12.2201 16.1799 12.3501 16.3899C12.3801 16.4399 12.4101 16.4799 12.4601 16.5199L12.5201 16.5899L18.4701 22.5299C18.7601 22.8199 19.2301 22.8199 19.5301 22.5299C19.8201 22.2399 19.8201 21.7599 19.5301 21.4699L14.0501 15.9899Z" fill="#414142"></path>
              </g>
            </svg>
          </div>
          <div class="forget-box" v-if="forgotPassword==true" >忘记密码</div>
          <div class="forget-box" v-if="bindEmail==true">绑定邮箱</div>
        </div>
        <div class="login-content" >
          <div class="inputContanier signup">
            <el-form  class="login-rule-form">
              <el-form-item prop="username" class="reform-box">
                <div class="entry-input emailPhone">
                  <el-input
                      size="large"
                      autofocus
                      v-model="emailNum"
                      placeholder="请输入邮箱"
                  />
                </div>
              </el-form-item>
              <p class="error-message" v-if="!isValidEmail">请输入正确的邮箱</p>
              <el-form-item class="reform-box" >
                <div class="entry-input password">
                  <el-input
                      class="verify-input"
                      size="large"
                      v-model="verificationCode"
                      placeholder="请输入六位数验证码"
                  >
                  </el-input>
                  <button
                      class="get-code-btn"
                      @click="getVerifyCode"
                      :disabled="isLoading"
                  >
                    {{ isLoading && countDownTime !== 0 ? `${countDownTime}s` : '获取验证码' }}
                  </button>
                </div>
                <span class="mail-address" @click="routeMailAdress" v-if="isLoading">
                    <svg class="hand-icon pointing-hand" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16 11.5v1a2 2 0 0 1-2 2m0 0h-1m1 0a1 1 0 0 1 1 1a2 2 0 0 1-2 2m0 0h-1m1 0c.524 0 .922.47.836.987l-.058.342a2 2 0 0 1-1.972 1.671h-1.473c-2.173 0-3.26 0-4.125-.331c-.502-.192-1.142-.699-1.604-1.104a2.2 2.2 0 0 0-1.44-.565H2m12-7h6.5a1.5 1.5 0 0 0 0-3h-9.962l1.625-1.62a1.683 1.683 0 0 0-.14-2.51a1.696 1.696 0 0 0-2.072-.033l-4.598 3.6C4.473 8.626 3.117 9 2 9" color="currentColor"/></svg>
                    点击查看验证码
                  </span>
              </el-form-item>
              <el-form-item v-if="forgotPassword==true" prop="password" class="reform-box" >
                <div class="entry-input password">
                  <el-input
                      class="verify-input"
                      size="large"
                      v-model="codeNum"
                      type="password"
                      show-password
                      autocomplete="off"
                      placeholder="请输入密码"
                  >
                  </el-input>
                </div>
              </el-form-item>
              <el-form-item style="margin-bottom: 12px">
                <div class="entry-input password">
                  <el-button type="primary" size="large" class="btn-login" @click="resetPassword" v-if="forgotPassword==true">重置密码</el-button>
                  <el-button type="primary" size="large" class="btn-login" @click="toBindEmail" v-if="bindEmail==true">绑定</el-button>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref ,onUnmounted,onMounted,watch} from 'vue'
import md5 from 'js-md5'
import {createWisetaoQrCode, wisetaoCheckScan, forgetPassword, onBindEmail, account, sendMailVerificationCode} from '@/api/loginApi.js'
import { useAppStore } from '@/stores/index'
import SwitchWechat from '@/assets/icons/switch_wechat.vue'
import SwitchPhoneMail from '@/assets/icons/switch_phone_mail.vue'
import {useHooks} from "@/hooks";
import { EMAIL_REGEX_ADRESS_MAP } from '@/const'
const {routeToWorkspace} = useHooks();
const store = useAppStore()
let isAgree=ref(false)
// 邮箱
const emailNum = ref('');
const username = ref('');
// 验证码
const verificationCode = ref('');
//密码
const codeNum=ref('');
// 验证码错误提示
const errorVerificationCode = ref(true);
//密码错误提示
const errorCodeNum=ref(true);
// 验证邮箱是否有效
const isValidEmail = ref(true);
// 验证表单是否有效
const isFormValid = ref(false);
const wxLoginUrl=ref("")
const intervalId=ref<number | null>(null);
// 定义当前登录方式，默认验证码登录
const currentLoginType = ref('wxLogin');
const showTip = ref(false);
const sceneStr = ref('');
const userWxOpenId = ref('');
// 验证邮箱格式
const validateEmail = () => {
  if (emailNum.value) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    isValidEmail.value = regex.test(emailNum.value);
  } else {
    isValidEmail.value = false;
  }
};

// 监听邮箱输入变化
watch(emailNum, (newVal) => {
  if (newVal === '') {
    isValidEmail.value = false; // 输入为空时不显示错误
  }
});

function handleClose() {
  store.setLoginShowStatus(false)
  clearInterval(intervalId.value as number);
}
//切换登录方式
function changeWay() {
  clearInterval(intervalId.value as number);
  isValidEmail.value = true
  errorVerificationCode.value = true
  errorCodeNum.value = true
  if(currentLoginType.value == 'wxLogin'){
    currentLoginType.value='verify'
  }else{
    currentLoginType.value='wxLogin'
  }
}
function changeWay2() {
  clearInterval(intervalId.value as number);
  isValidEmail.value = true
  errorVerificationCode.value = true
  errorCodeNum.value = true
  if(currentLoginType.value == 'password'){
    currentLoginType.value='verify'
  }else{
    currentLoginType.value='password'
  }
}
//校验
const handleInput=()=>{
  isValidEmail.value = true
  errorVerificationCode.value = true
  errorCodeNum.value = true
}
//验证码登录
const handleEmailLogin = async () => {
  validateEmail()
  if(isValidEmail.value){
    if(verificationCode.value!=""){
      let res = await account({
          email: emailNum.value,
          verifyCode: verificationCode.value,
          action:store.isRegistry?1:3
        })
      if(res.code=='DPVERIFYCODEEXPIRE'){
        isLoading.value = false;
      }else if(res.code == '0'){
        store.setUserInfo(res.data)
        routeToWorkspace(res.data)
      }
    }
  }
};
//密码登录
const handleCodeLogin = async () => {
  if(username.value!="" && codeNum.value!=""){
    let res = await account({
        userName: window.btoa(username.value),
        password: md5(codeNum.value),
        action:store.isRegistry?1:3
      })

    if(res.code == '0'){
      store.setUserInfo(res.data)
      routeToWorkspace(res.data)
    }
  }
};
//重置密码
const resetPassword = async () => {
  validateEmail()
  if(isValidEmail.value){
    if(verificationCode.value!="" && codeNum.value!=""){
      const res = await forgetPassword({
        email: emailNum.value,
        verifyCode: verificationCode.value,
        password: md5(codeNum.value)
      })
      if(res.code == '0'){
        goBack()
      }
    }
  }

};
//邮箱绑定
const toBindEmail = async () => {
  validateEmail()
  if(isValidEmail.value){
    if(verificationCode.value!=""){
      const res = await onBindEmail({
        email: emailNum.value,
        verifyCode: verificationCode.value ,
        userWxOpenId: userWxOpenId.value
      })
      if(res.code=='DPVERIFYCODEEXPIRE'){
        isLoading.value = false;
      }else if(res.code == '0'){
        store.setUserInfo(res.data)
        routeToWorkspace(res.data)
      }
   }
 }
};

//获取二维码
watch(() => [store.isPopLogin,currentLoginType.value], async (newValue, oldValue) => {
  if(newValue[0] && newValue[1] == 'wxLogin'){
    getCreateWisetaoQrCode()
  }
},{
  immediate: true,
});

async function getCreateWisetaoQrCode() {
  const res = await createWisetaoQrCode()
  if (res.code === '0') {
      wxLoginUrl.value = res.data.qrCodeUrl
      sceneStr.value = res.data.sceneStr
    }
  intervalId.value = setInterval(getWisetaoCheckScan, 3000);
}
//轮询
const isRegister=ref(false)
function getWisetaoCheckScan() {
  if(!sceneStr.value) return
  wisetaoCheckScan({
    sceneStr:sceneStr.value,
  }).then((resp: any) => {
        const response = resp
        if (response.code === '0') {
          if(response.data.userWxOpenId != null){
            userWxOpenId.value = response.data.userWxOpenId
            clearInterval(intervalId.value as number);
            const developerLoginRespVO = response.data.developerLoginRespVO
            if(developerLoginRespVO != null){
              // 用户已经绑定邮箱
              if(developerLoginRespVO.email != null && developerLoginRespVO.email != ''){
                // TODO: 跳转工作台
                store.setUserInfo(developerLoginRespVO)
                routeToWorkspace(developerLoginRespVO)
              }else{
                // 未绑定邮箱
                bindEmail.value = true
                currentLoginType.value = 'verify'
              }
            }else{
              isRegister.value = true
              bindEmail.value = true
              currentLoginType.value = 'verify'
            }
          }
        }
      })
}
let timer:any;
//获取短信验证码
const countDownTime = ref(120);
const isLoading = ref(false);
const mailAdress=ref("")
const routeMailAdress = () => {
  window.open(mailAdress.value, '_blank')
}
const getVerifyCode = async () => {
  clearInterval(timer);
  if (isLoading.value) return;
  validateEmail()
  if(!isValidEmail.value) return
  isLoading.value = true;
  countDownTime.value = 120;
  timer = setInterval(() => {
    countDownTime.value--;
    if (countDownTime.value < 0) {
      clearInterval(timer as number);
      isLoading.value = false;
    }
  }, 1000);
  let res = await sendMailVerificationCode({
      email: emailNum.value,
    })
  for (const key in EMAIL_REGEX_ADRESS_MAP) {
      const adress = EMAIL_REGEX_ADRESS_MAP[key];
      if (new RegExp(key).test(emailNum.value)) {
        mailAdress.value = adress
      }
  }
  if(res.code === 'DEVELOPERALREADYSENDMAIL'){
    countDownTime.value = 0;
  }else if(res.code !== '0'){
    isLoading.value = false
    countDownTime.value = 0

  }
};

// 发送验证码相关
onUnmounted(() => {
  if (timer) {
    clearInterval(timer);
  }

});

//忘记密码
const forgotPassword=ref(false)
const goBack = (loginType = 'password') => {
  currentLoginType.value = bindEmail.value? 'wxLogin' :loginType
  bindEmail.value=false
  forgotPassword.value=false

};

//绑定邮箱
const bindEmail=ref(false)

// 定义判断大页面和小页面的阈值
const breakpoint = 768;

// 根据视口宽度设置 currentLoginType 的初始值
const setInitialLoginType = () => {
  if (window.innerWidth >= breakpoint) {
    // 大页面设置
    currentLoginType.value = 'wxLogin';
  } else {
    // 小页面设置
    currentLoginType.value = 'verify';
  }
};

// 监听窗口大小变化
const handleResize = () => {
  setInitialLoginType();
};
onMounted(() => {
  // 页面加载时设置初始值
  //setInitialLoginType();
  // 监听窗口 resize 事件
  //window.addEventListener('resize', handleResize);
  if (intervalId.value) {
    clearInterval(intervalId.value);
    intervalId.value = null;
  }
});

onUnmounted(() => {
  // 组件卸载时移除事件监听器，避免内存泄漏
  window.removeEventListener('resize', handleResize);
});
</script>
<style lang="less" scoped>
rect {
  transition: fill-opacity 0.3s;
}
rect:hover {
  fill-opacity: 0.1;
}
.close-login {
  width: 32px;
  height: 32px;
  position: absolute;
  left: 460px;
}


.login-container {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: auto;
  z-index: 999;
  position: fixed;
  background-color: #00000080;
}
.above-content {
  height: calc(100% - 80px);
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  top:80px;
  z-index: 2;
  position: relative;
  animation: zoomIn 0.3s ease-out forwards;
}

@keyframes zoomIn {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.modal-use-left-section {
  width: 280px;
  height: 480px;
  border-radius: 12px 0px 0px 12px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
}
.modal-use-right-section {
  width: 440px;
  padding: 0px 72px;
  border-radius: 0px 12px 12px 0px;
  user-select: none;
  font-size: 14px;
  //display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  height: 480px;
  box-shadow:
    rgba(0, 0, 0, 0.04) 0px 0px 4px 0px,
    rgba(0, 0, 0, 0.04) 0px 4px 16px 0px,
    rgba(0, 0, 0, 0.06) 0px 8px 24px 0px;
  background-color: rgb(255, 255, 255);
  position: relative;
  z-index: 1;
}
.login-cut{
  position: absolute;
  z-index: 2;
  right: 0px;
  cursor: pointer;
}

.tip-box {
  position: absolute;
  top: 4%;
  right: 70px;
  background: rgb(217, 227, 252);
  color: rgb(51, 111, 255);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  z-index: 10;
}
.login-header {
  padding-top: 66px;
  width: 100%;
  .header-text {
    width: 100%;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    color: rgb(25, 25, 26);
    margin-bottom: 2px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
  }
}
.wechat-des {
  width: 100%;
  display: flex;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: rgb(139, 140, 143);
}
.login-content {
  position: relative;
  width: 100%;
  font-size: 12px;
  margin-top: 30px;
  .wxLogin-box{
    margin-left: 50px;
    width: 200px;
    height: 200px;
  }

  .login-switch {
    display: flex;
    margin-bottom: 12px;
    color: #1b1b1c;
    background-color: rgb(238,238,238);
    width: 178px;
    border-radius: 6px;
    font-size: 14px;
  }
  .login-btn {
    margin: 1px 1px;
    padding: 7px 12px;
    border: 0px solid rgb(238,238,238); /* 初始边框样式 */
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s;
    outline: none;
  }
  .reform-box{
    margin-bottom: 12px;
    border-radius: 6px;
  }
  .entry-input {
    width: 100%;
    .verify-input {
      position: relative;
      z-index: 1;
      height: 100%;
      border: none;
      outline: none;
      font-size: 14px;
      color: #333;
      background: transparent;
    }

    .get-code-btn {
      position: absolute;
      z-index: 2;
      right: 10px;
      top:10px;
      border: none;
      outline: none;
      font-size: 14px;
      color: rgb(51, 111, 255);
      background: transparent;
      cursor: pointer;
    }
    ::v-deep {
      .el-input__inner {
        line-height: normal; /* 恢复自然行高 */
      }
    }
    .get-code-btn:disabled {
      color: #575757;
      cursor: not-allowed;
    }
  }
  :deep(.el-input__wrapper) {
    background-color: rgb(247, 247, 249);
    padding: 0px 8px
  }
  .agreement-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 12px;
    color: #666;
    gap: 4px;
  }
  .agree-checkbox {
    margin-right: 6px;
    margin-left: 6px;
    transform: scale(1); /* 调整复选框大小 */
  }
  .agree-checkbox:hover {
      cursor: pointer;
  }

  .link-style {
    color: rgb(51, 111, 255);
    text-decoration: none;
    cursor: pointer;
  }

  .link-style:hover {
    text-decoration: none;
  }
  .forgot_password{
    float: right;
    color: red;
  }
  .forgot_password:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  .btn-login {
    width: 100%;
    outline: none;
  }
}
.password-eye {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 8px;
  top: 8px;
  cursor: pointer;
  fill: currentcolor;
}
.footer {
  width: 100%;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  font-size: 12px;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  .confirm-agreement {
    position: relative;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    margin-left: 36px;
  }
  .signway-container {
    width: 100%;
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: end;
    justify-content: flex-end;
    margin-top: 24px;
    .line-group {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      margin-bottom: 24px;
    }

    .line {
      flex: 1;
      height: 1px;
      background-color: #e4e7ed;
    }

    .line-group span {
      color: #999;
      font-size: 12px;
    }
    .divider {
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      color: rgb(183, 185, 189);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      white-space: nowrap;
    }
    .login-way {
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      .login-way-icon {
        width: 40px;
        height: 40px;
        margin: 0px 10px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 50%;
        background-color: rgb(255, 255, 255);
        border: 1px solid rgba(18, 17, 42, 0.07);
        transition: 100ms;
        position: relative;
      }
    }
  }
}
@media (max-width: 767px) {
  .modal-use-right-section {
    border-radius: 12px 12px 12px 12px;
    font-size: 14px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .close-login{
    left:50%;
    top:-40px;
  }

}

.back-icon {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  :hover{
    cursor: pointer;
  }
}

.back-icon svg {
  width: 30px;
  height: 30px;
}
.forget-box{
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  font-size: 20px;
}
.error-message{
  color: #e10816;
  font-size: 12px;
  margin-bottom: 6px;
  margin-top: -10px;
}
.mail-address{
  font-size: 12px;
  color: #409EFF;
  cursor: pointer;
  line-height: 20px;
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  transition: color 0.3s;
}
.mail-address:hover {
  color: #66b1ff;
  text-decoration: underline;
}
.mail-address .hand-icon {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}

.pointing-hand {
  animation: pointing-animation 1.2s ease-in-out infinite;
}

@keyframes pointing-animation {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(3px);
  }
  100% {
    transform: translateX(0);
  }
}

</style>
