<template>
  <span ref="numRef"></span>
</template>

<script setup>
import { ref, onMounted } from 'vue';
// 修正后的命名导入
import { CountUp } from 'countup.js';

const props = defineProps({
  startNum: { type: Number, default: 0 },
  endNum: { type: Number, required: true },
  decimalPlaces: { type: Number, default: 0 }, // 添加小数位数配置
  options: {
    type: Object,
    default: () => ({
      duration: 4, // 动画持续时间（秒）
      useEasing: true,
      useGrouping: true
    })
  }
});


const numRef = ref(null);

onMounted(() => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        // 合并选项，添加小数位数
        const countUpOptions = {
          ...props.options,
          decimalPlaces: props.decimalPlaces
        };
        const countUpIns = new CountUp(
            numRef.value,
            props.endNum,
            countUpOptions
        );

        if (countUpIns.error) {
          console.error('数字滚动初始化错误:', countUpIns.error);
          return;
        }
        countUpIns.start();
        observer.unobserve(entry.target); // 停止观察
      }
    });
  }, {
    rootMargin: '0px',
    threshold: 0.5
  });

  if (numRef.value) {
    observer.observe(numRef.value);
  }
});
</script>
