<script setup lang="ts">
import {  RouterView } from 'vue-router'
import { useAppStore } from '@/stores/index.ts'
import {getUserInfo} from "@/api/loginApi.js";
const store = useAppStore()
async function loginPopCheck() {
  let loginStatus = false;
  let token = storeUtils.getToken();
  let openId = storeUtils.getOpenId();
  if (
    store.isLogin ||
    (token &&
      openId &&
      token != "null" &&
      openId != "null" &&
      token != "undefined" &&
      openId != "undefined")
  ) {
    loginStatus = true;
    const res = await getUserInfo();
    if(res.code === '0'){
      store.setUserInfo(res.data)
    }
  } else {
    loginStatus = false;
  }
  store.setLoginStatus(loginStatus);
  return loginStatus;
}
loginPopCheck()
</script>
<template>
  <RouterView />
</template>
<style>
html{
  box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font-size: 16px;
    line-height: 1.2;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Pingfang SC", "Microsoft Yahei", "WenQuanYi Micro Hei", sans-serif;
    color: rgb(82, 94, 113);
    -webkit-tap-highlight-color: transparent;

}
body {
  background-color: transparent;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow: hidden auto;
    font-size: 0.875rem;
    -webkit-font-smoothing: antialiased;
}
input {
    line-height: normal;
}
button, input, textarea {
    margin: 0px;
    padding: 0px;
    appearance: none;
    background: none;
    border: 0px;
    outline: 0px;
}
button, input, optgroup, select, textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0px;
}
input:not([type="image" i], [type="range" i], [type="checkbox" i], [type="radio" i]) {
    overflow-clip-margin: 0px !important;
    overflow: clip !important;
}

input {
    text-rendering: auto;
    color: fieldtext;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    appearance: auto;
    -webkit-rtl-ordering: logical;
    cursor: text;
    background-color: field;
    margin: 0em;
    padding: 1px 0px;
    border-width: 2px;
    border-style: inset;
    border-color: light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-image: initial;
    padding-block: 1px;
    padding-inline: 2px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected {
    -webkit-text-fill-color: inherit !important;
    -webkit-box-shadow: 0 0 0 30px transparent inset !important;
    background-color: transparent !important;
    transition: background-color 5000s ease-in-out 0s;
}
#app{ height: 100%; width: 100%;}
</style>
