<template>
  <header class="large-screen wsc-wisetao-header">
    <nav class="wisetao-navbar">
      <div class="wisetao-navbar-container">
        <div class="wisetao-navbar-content">
          <div class="wisetao-navbar-collapse">
            <div class="wisetao-navbar-brand web-brand">
              <a class="wisetao-navbar-brand-href" rel="nofollow" href="https://wisetao.cn">
                <img src="@/assets/imgs/logo.svg" alt="wisetao-logo" class="wisetao-logo" />
              </a>
            </div>
            <ul class="wisetao-navbar-menu"></ul>
            <ul class="wisetao-navbar-menu">
              <li
                class="wisetao-navbar-item wondershare-user-panel nav-md-login-btn px-xxl-4 log-out"
              >
                <div class="wisetao-navbar-login text-center text-md-left">
                  <a
                    v-if="!store.isLogin"
                    class="wisetao-navbar-btnlogin font-weight-medium"
                    style="user-select: none;"
                    @click="store.setLoginShowStatus(true)"
                    >登录</a
                  ><span class="wisetao-navbar-btnregister font-weight-medium" style="user-select: none;"  @click="registryOrWorkspace">{{
                    store.isLogin ?  '进入工作台' : '注册'
                  }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </header>
  <header class="d-lg-none wsc-wisetao-header">
    <!-- 小屏头部内容 -->
    <div class="header-container">
      <div class="logo-container">
        <img
            src="@/assets/imgs/logo.svg"
            alt="logo"
            class="logo-img"
        >
      </div>
      <div class="wisetao-navbar-login text-center text-md-left" style="padding: 16px 0">
        <a
            v-if="!store.isLogin"
            class="wisetao-navbar-btnlogin font-weight-medium"
            style="user-select: none;"
            @click="store.setLoginShowStatus(true)"
        >登录</a
        ><span class="wisetao-navbar-btnregister font-weight-medium" style="user-select: none;"  @click="registryOrWorkspace">{{
          store.isLogin ? '进入工作台' : '注册'
        }}</span>
      </div>
    </div>
  </header>
  <main
    class="wsc-main p-0 position-relative text-center"
    data-toggle="alert"
    style="color: #000; background: #f9fcff"
  >
    <section class="banner py-4 py-md-5 my-5">
      <div class="container banner-content">
        <!-- new -->
        <h1 class="font-weight-semi-bold display-1 title-1">智道，助力企业高效生成数字化</h1>
        <h1 class="font-weight-semi-bold display-1 title-1">应用的智能平台</h1>
        <p class="font-size-large mb-4 pb-xxl-1 color1" style="margin-top: 10px">
          一站式数字生产力引擎，覆盖设计-分析-开发全场景
        </p>

        <!-- new -->
        <div v-if="store.isLogin"  class="text-white mb-6">
          <span
            @click="registryOrWorkspace"
            style="user-select: none;"
            class="btn-login btn-1 btn-type font-weight-medium font-size-large purple-red-gradient-bg"
            >进入工作台</span>
        </div>
        <div v-else class="text-white mb-6">
          <span
            @click="registryOrWorkspace(false)"
            target="_blank"
            class="btn-1 btn-type font-weight-medium font-size-large purple-red-gradient-bg"
            style="user-select: none;"
            >免费使用</span>
        </div>
        <div class="pb-4 pb-lg-5">
          <div class="d-none d-md-block">
            <img class="img-fluid" style="min-height: 540px;" loading="lazy" src="@/assets/imgs/pc_introduce.png" alt="banner" />
          </div>
          <div class="d-md-none">
              <img class="img-fluid" rel="nofollow" src="@/assets/imgs/mobile_introduce.png" alt="banner" />
          </div>
        </div>
      </div>
    </section>

    <section class="screen py-5 my-4 my-xl-5">
      <div class="container">
        <h2 ref="targetText" class="animate-text">
          <span class="gradient-text1">全业务、</span>
          <span class="gradient-text2">全流程、</span>
          <span class="gradient-text3">全要素、</span>的解决方案和生态体系
        </h2>
        <p class="font-size-large mb-4 pb-xxl-1 color1">目前智道平台已拥有</p>
        <div class="screen-nav mb-4 mb-md-5 d-flex align-items-center justify-content-center">
          <div class="row flex-wrap col-lg-10" >
              <div class="col-md-6 col-lg-3" v-for="(item,index) in statisticLeftList" :key="item.type">
                  <div class="card-item">
                      <div class="d-flex align-items-center justify-content-center">
                          <div class="statis-item"  :style="{ backgroundImage: `url(${item.imgUrl})` }" >
                              <div style="font-weight: bold;font-size: 26px;height: 30px;margin-top: 15px;font-family: 'CustomFont';">
                                  <NumberScroll :endNum="item.num" :decimalPlaces="decimal(item.num)" />
                              </div>
                              <div style="font-size: 14px;margin-bottom: 14px">{{appendUnit(item.type)}}</div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <Login></Login>
    <footer class="app-footer">
        <div class="copyright">
          版权所有 © 安徽智道百业数字科技有限公司 <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" class="footer-link" >皖ICP备2023015590号-1</a>
        </div>
    </footer>

</template>
<script lang="ts" setup>
import Login from '@/components/login.vue'
import NumberScroll from '@/components/NumberScroll.vue'
import { useAppStore } from '@/stores/index.ts'
import {ref, onMounted} from "vue";
import img1 from '@/assets/imgs/bg1.png'
import img2 from '@/assets/imgs/bg2.png'
import img3 from '@/assets/imgs/bg3.png'
import img4 from '@/assets/imgs/bg4.png'
import img5 from '@/assets/imgs/bg5.png'
import img6 from '@/assets/imgs/bg6.png'
import img7 from '@/assets/imgs/bg7.png'
import img8 from '@/assets/imgs/bg8.png'
import {getSystemMarks} from "@/api/loginApi.js";
import {ElMessage} from "element-plus";
import {useHooks} from "@/hooks";
const {routeToWorkspace} = useHooks();
const store = useAppStore()

// 控制菜单显示状态
const statisticLeftList=ref([
  {
    imgUrl:img1,
    type:'',
    num:127
  },
  {
    imgUrl:img2,
    type:'',
    num:497
  },{
    imgUrl:img3,
    type:'',
    num:13019
  },{
    imgUrl:img4,
    type:'',
    num:452
  },{
        imgUrl:img5,
        type:'',
        num:20
    },
    {
        imgUrl:img6,
        type:'',
        num:4468
    },{
        imgUrl:img7,
        type:'',
        num:155
    },{
        imgUrl:img8,
        type:'',
        num:16
    }
])
const targetText = ref(null);

onMounted(() => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        // 添加一个小延迟，确保动画更加平滑
        setTimeout(() => {
          entry.target.classList.add('in-view');
        }, 100);
        // 立即停止观察，确保动画只触发一次
        observer.unobserve(entry.target);
      }
    });
  }, {
    rootMargin: '0px', // 定义元素边缘与视口边缘的距离
    threshold: 0.5 // 当50%的元素进入视口时触发回调，使动画更早触发
  })

  if (targetText.value) {
    observer.observe(targetText.value);
  }

  getSystemMarks()
      .then((resp: any) => {
        const response = resp
        const resdata = response.data
        if (response.code === 0) {
          for(let i=0;i<resdata.length;i++){
            statisticLeftList.value[i].type=resdata[i].name
            statisticLeftList.value[i].num=resdata[i].cnt
          }
        } else {
          ElMessage({
            message: response.msg,
            type: 'error',
          })
        }
      })
      .catch(() => {
      })
});
// 判断数字有几位小数的函数
const decimal = (num: number): number => {
  if (Number.isInteger(num)) return 0;
  const strNum = num.toString();
  const decimalPart = strNum.split('.')[1];
  return decimalPart ? decimalPart.length : 0;
};

const appendUnit = (type: string): string => {
  if (type === '管理测点数') {
    return  `${type}(万)`;
  }
  return type
}

const registryOrWorkspace = (registry = true) => {
  if(store.isLogin){
    routeToWorkspace()
  }else{
    if(registry){
      store.setRegShowStatus(true)
    }else{
      store.setLoginShowStatus(true)
    }

  }
}
</script>
<style>
@font-face {
  font-family: 'CustomFont';
  src: url('@/assets/LCD.ttf') format('truetype');
}
.statis-item{
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  float: left
}
.animate-text {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease, transform 0.8s ease;
  line-height: 1.6;
  font-size: 40px;
  will-change: transform, opacity; /* 优化性能 */
}

.animate-text.in-view {
  opacity: 1;
  transform: translateY(0);
}
.gradient-text3{
  background: -webkit-linear-gradient(left, #ff7e5f, #ffde5f); /* 从左到右的渐变 */
  -webkit-background-clip: text; /* 仅将背景应用至文本 */
  color: transparent; /* 文本颜色设置为透明，以便显示背景渐变 */
  display: inline-block; /* 确保文本块级元素，以便背景正确显示 */
}
.gradient-text2{
  background: -webkit-linear-gradient(left, #cc3543,#f5a1a9); /* 从左到右的渐变 */
  -webkit-background-clip: text; /* 仅将背景应用至文本 */
  color: transparent; /* 文本颜色设置为透明，以便显示背景渐变 */
  display: inline-block; /* 确保文本块级元素，以便背景正确显示 */
}
.gradient-text1{
  background: -webkit-linear-gradient(left,  #a628ea, #d899fa); /* 从左到右的渐变 */
  -webkit-background-clip: text; /* 仅将背景应用至文本 */
  color: transparent; /* 文本颜色设置为透明，以便显示背景渐变 */
  display: inline-block; /* 确保文本块级元素，以便背景正确显示 */
}
.card-item {
    //padding: 0 1.5rem;
    margin-bottom: 1rem;
    border-radius: 0.3rem;
}
.app-footer {
    background-color: #1890ff;
    color: white;
    padding: 20px;
    text-align: center;
}

.footer-link {
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
    margin: 0 10px;
}
.footer-link:hover {
    color: #fff;
}
a:hover{
  text-decoration: none;
}
.copyright {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
}


.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1000;
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.logo-img {
  width: 90px;
  height: 30px;
}

.menu-icon {
  cursor: pointer;
  padding: 8px;
}

.menu-icon svg {
  width: 24px;
  height: 24px;
}

.menu-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 24px;
  z-index: 1001;
}

.menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.close-icon {
  cursor: pointer;
}

</style>
