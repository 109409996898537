<template>
 <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M63.9529 64H64V12C64 5.37258 58.6274 0 52 0H0V0.0471191L63.9529 64Z" fill="#336FFF" fill-opacity="0.1"/>
  <mask id="mask0_2022_671" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="64" height="64">
  <path d="M64 64V12C64 5.37256 58.6289 0 52 0H0V0.0472412L63.9531 64H64Z" fill="#7856FF"/>
  </mask>
  <g mask="url(#mask0_2022_671)">
  <path d="M40 42V40H34V34H40V38H44V42H42V46H38V50H34V44H38V42H40ZM50 50H42V46H46V42H50V50ZM14 14H30V30H14V14ZM18 18V26H26V18H18ZM34 14H50V30H34V14ZM38 18V26H46V18H38ZM14 34H30V50H14V34ZM18 38V46H26V38H18ZM44 34H50V38H44V34ZM20 20H24V24H20V20ZM20 40H24V44H20V40ZM40 20H44V24H40V20Z" fill="#336FFF"/>
  </g>
  </svg>

</template>

<script lang="ts">
export default {
  name: 'switch_wechat'
}
</script>
