import { createRouter, createWebHashHistory } from 'vue-router'
import Index from '../views/Index.vue'
// import Login from '../views/Login.vue'

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: Index,
    },
    {
      path: '/index',
      name: 'index',
      component: Index,
    },
    {
      path: '/agreement',
      name: 'agreement',
      component: () => import('@/components/agreement.vue')
    },{
      path: '/policy',
      name: 'policy',
      component: () => import('@/components/policy.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login.vue')
    },
  ],
})

export default router
