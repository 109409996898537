const RESHEEADER = globalConfig.requestHeader;

window.storeUtils = {
  getLocalStorageItem: function (key, undecode) {
    let o = localStorage[key];
    if (typeof o === "undefined") {
      return null;
    } else {
      return !undecode ? decodeURIComponent(o) : o;
    }
  },
  /**
   * 设置LocalStorage
   * @param {[type]} key   [键名]
   * @param {[type]} value [键值]
   * @param {[type]} days  [保存的时间（天）]
   */
  setLocalStorageItem(key, value) {
    localStorage[key] = value == null ? "" : value;
  },
  deleteLocalstorage(key) {
    window.localStorage.removeItem(key);
  },
  /**
   * 获取sessionStorage
   * @param {string} key 要从cookie中获取的关键字
   *
   * @param {string} undecode 是否不需要解码 （默认解码）
   */
  getSessionItem: (key, undecode) => {
    let item = sessionStorage.getItem(key);
    if (!item || item == "null" || item == "undefined") {
      item = "";
    }
    return !undecode ? decodeURIComponent(item) : item;
  },
  /**
   * 设置获取sessionStorage
   * @param {string} key  要存入session中的的关键字
   * @param {string} value 要存入session中的的值
   */
  setSessionItem: (key, value) => {
    sessionStorage.setItem(key, encodeURIComponent(value));
  },
  /**
   * 获取cookie
   * @param {string} key 要从cookie中获取的关键字
   * @param {string} undecode 是否不需要解码 （默认解码）
   */
  getCookie: (key, undecode) => {
    const reg = new RegExp("(^| )" + key + "=([^;]*)(;|$)");
    const arr = document.cookie.match(reg);
    let res = "";
    if (arr && arr[2] && arr[2] != "null" && arr[2] != "undefined") {
      res = arr[2];
    }
    return !undecode ? decodeURIComponent(res) : res;
  },
  /**
   * 设置cookie
   * @param {string} key  要存入cookie中的的关键字
   * @param {string} value 要存入cookie中的的值
   * @param {timestamp/number} expires cookie过期时间（当参数为数字时，则表示cookie过期天数）
   */
  setCookie: (key, value, expires) => {
    if (!value) return false;
    let cookieText = key + "=" + encodeURIComponent(value) + ";path=/";
    if (expires) {
      if (expires instanceof Date) {
        cookieText += ";expires=" + expires.toGMTString();
      } else {
        const date = new Date();
        date.setDate(date.getDate() + expires);
        cookieText += ";expires=" + date.toGMTString();
      }
    }
    document.cookie = cookieText;
  },
  /**
   * 使cookie过期
   * @param {string} key  要存入cookie中的的关键字
   */
  deleteCookie: (key) => {
    storeUtils.setCookie(key, " ", -1);
  },
  //token
  setToken: (newToken) => {
    const date = new Date();
    date.setTime(date.getTime() + 30 * 60 * 1000); // 设置有效期30min
    storeUtils.setCookie("ecosystem-token", newToken);
    storeUtils.setSessionItem("ecosystem-token", newToken);
  },
  getToken: () => {
    return storeUtils.getCookie("ecosystem-token");
  },
  deleteToken: () => {
    sessionStorage.removeItem("ecosystem-token");
    storeUtils.deleteCookie("ecosystem-token");
  },
  //openId
  setOpenId: (newOpenId) => {
    storeUtils.setSessionItem("ecosystem-openid", newOpenId);
    storeUtils.setCookie("ecosystem-openid", newOpenId);
  },
  getOpenId: () => {
    return storeUtils.getCookie("ecosystem-openid");
  },
  deleteOpenId: () => {
    sessionStorage.removeItem("ecosystem-openid");
    storeUtils.deleteCookie("ecosystem-openid");
  },
  //appId
  setAppId: (appId) => {
    storeUtils.setSessionItem(RESHEEADER.APPID, appId);
  },
  getAppId: () => {
    return storeUtils.getSessionItem(RESHEEADER.APPID);
  },
  deleteAppId: () => {
    return storeUtils.setSessionItem(RESHEEADER.APPID,'');
  },
  //appName
  setAppName: (appName) => {
    storeUtils.setSessionItem(RESHEEADER.APPNAME, appName);
  },
  getAppName: () => {
    return storeUtils.getSessionItem(RESHEEADER.APPNAME);
  },
  //appDomain
  setAppDomain: (appDomain) => {
    storeUtils.setSessionItem(RESHEEADER.APPDOMAIN, appDomain);
  },
  getAppDomain: () => {
    return storeUtils.getSessionItem(RESHEEADER.APPDOMAIN);
  },
  //AGP
  setAGP: (agp) => {
    storeUtils.setSessionItem('WT-ISAGP', agp);
  },
  getAGP: () => {
    return storeUtils.getSessionItem('WT-ISAGP');
  },
   //AGP
   setSceneId: (sceneId) => {
    storeUtils.setSessionItem('WT-SCENEID', sceneId);
  },
  setAppType: (type) => {
    storeUtils.setSessionItem('WT-APPTYPE', type);
  },
  //记住用户ID
  setUserId(userId) {
    storeUtils.setLocalStorageItem("userId", userId);
  },
  getUserId: () => {
    return storeUtils.getLocalStorageItem("userId");
  },
  deleteUserId: () => {
    storeUtils.deleteLocalstorage("userId");
  },
  //用户类型
  setUserType(userType) {
    // storeUtils.setCookie("userType", userType, 7); // 设置有效期7天
    storeUtils.setCookie("userType", userType);
  },
  getUserType: () => {
    return storeUtils.getCookie("userType");
  },
  deleteUserType: () => {
    storeUtils.deleteCookie("userType");
  },
  // 记住用户账号
  setUserName(userName) {
    // storeUtils.setCookie("userName", userName, 7); // 设置有效期7天
    storeUtils.setCookie("userName", userName);
  },
  getUserName: () => {
    return storeUtils.getCookie("userName");
  },
  deleteUserName: () => {
    storeUtils.deleteCookie("userName");
  },
  // 记住用户名称
  setUserInfoName(name) {
    // storeUtils.setCookie("name", name, 7); // 设置有效期7天
    storeUtils.setCookie("name", name);
  },
  getUserInfoName: () => {
    return storeUtils.getCookie("name");
  },
  deleteUserInfoName() {
    storeUtils.deleteCookie("name"); // 设置有效期7天
  },
  //记住用户头像
  getUserImgUrl: () => {
    return storeUtils.getLocalStorageItem("userImgUrl");
  },
  setUserImgUrl: (imgUrl) => {
    storeUtils.setLocalStorageItem("userImgUrl", imgUrl);
  },
  deletetUserImgUrl: () => {
    storeUtils.deleteLocalstorage("userImgUrl");
  },
  setUserPhone(phone) {
    storeUtils.setCookie("phone", phone);
  },
  getUserPhone: () => {
    return storeUtils.getCookie("phone");
  },
  getUrlParam(name) {
    return (
      decodeURIComponent(
        (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|;|$)").exec(
          location.href
        ) || [, ""])[1].replace(/\+/g, "%20")
      ) || null
    );
  },
  getTokenHeader: () => {
    let header = {
      ["ecosystem-token"]: storeUtils.getToken(),
      ["ecosystem-openid"]: storeUtils.getOpenId(),
    };
    return header;
  },
  //获取用户信息
  getUserInfo: () => {
    return {
      userId: storeUtils.getUserId(),
      type:storeUtils.getUserType(),
      userName: window.atob(storeUtils.getUserName()),
      phone: storeUtils.getUserPhone(),
      name: storeUtils.getUserInfoName(),
      token: storeUtils.getToken(),
      openId: storeUtils.getOpenId(),
      imgUrl: storeUtils.getUserImgUrl(),
    };
  },
};
