import { createApp } from 'vue'
import { createPinia } from 'pinia'
import 'animate.css';
import App from './App.vue'
import "./utils/storeUtils";
import router from './router'
// 导入Element Plus消息提示组件的样式
import 'element-plus/es/components/message/style/css'
const app = createApp(App)
const pinia = createPinia()
app.use(pinia)
app.use(router)
app.mount('#app')
