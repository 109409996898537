<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
<mask id="mask0_2028_2538" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="64" height="64">
<path d="M64 0H0V64H64V0Z" fill="white"/>
</mask>
<g mask="url(#mask0_2028_2538)">
<mask id="mask1_2028_2538" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="64" height="64">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H64V64L0 0Z" fill="white"/>
</mask>
<g mask="url(#mask1_2028_2538)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H64V64L0 0Z" fill="#336FFF" fill-opacity="0.1"/>
</g>
<mask id="mask2_2028_2538" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="64" height="64">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H64V64L0 0Z" fill="white"/>
</mask>
<g mask="url(#mask2_2028_2538)">
<path d="M49 12H27C25.3431 12 24 13.3431 24 15V53C24 54.6569 25.3431 56 27 56H49C50.6569 56 52 54.6569 52 53V15C52 13.3431 50.6569 12 49 12Z" fill="#336FFF"/>
</g>
<mask id="mask3_2028_2538" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="64" height="64">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H64V64L0 0Z" fill="white"/>
</mask>
<g mask="url(#mask3_2028_2538)">
<path d="M43.75 16H32.25C31.5596 16 31 16.5596 31 17.25C31 17.9404 31.5596 18.5 32.25 18.5H43.75C44.4404 18.5 45 17.9404 45 17.25C45 16.5596 44.4404 16 43.75 16Z" fill="white"/>
</g>
</g>
</svg>
</template>

<script lang="ts">
export default {
  name: 'switch_phone_mail'
}
</script>
