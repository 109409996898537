import request from "@/api/request";


// 获取用户信息
export function getUserInfo() {
  return request({
    url: "/platform/developer/ecosystem/info",
    method: "get",
  });
}
//微信登录
//获取微信二维码
export function createWisetaoQrCode(query) {
  return request({
    url: "/infra/v1/user/wx/mp/createWisetaoQrCode",
    method: "get",
    params: query,
  });
}
//轮询
export function wisetaoCheckScan(query) {
  return request({
    url: "/infra/v1/user/wx/mp/wisetaoCheckScan",
    method: "get",
    params: query,
    headers:{
      "WT-APPID": "1",
    }
  });
}
//首页数据接口
export function getSystemMarks(){
  return request({
    url:"/platform/getSystemMarks",
    method:'GET',
  })
}
// 开发者发送邮箱验证码
export function sendMailVerificationCode(query) {
  return request({
    url: "platform/developer/ecosystem/sendMailVerificationCode",
    method: "post",
    data: query,
  });
}

//绑定邮箱
export function onBindEmail(query) {
  return request({
    url: "/platform/developer/ecosystem/bindEmail",
    method: "post",
    data: query,
  });
}
// 开发者重置密码
export function forgetPassword(query) {
  return request({
    url: "platform/developer/ecosystem/forgetPassword",
    method: "put",
    data: query,
  });
}

//登录
export function account(query) {
  return request({
    url: "/platform/developer/ecosystem/login/account",
    method: "POST",
    data: query,
    headers:{
      "WT-APPID": "1",
    }
  });
}
