export function replacePlaceholders(url,{token,openId,appInfoList}) {
  let appId = appInfoList?.[appInfoList.length - 1]?.id;
  const {TOKEN,OPENID,APPID} = globalConfig.requestHeader;
  const oldAppId = localStorage.getItem(APPID);
  appInfoList.find((item)=>{
    if(item.id == oldAppId){
      appId = item.id;
      return true;
    }
  })

  return url.replace('${'+APPID+'}', appId)
            .replace('${'+TOKEN+'}', token)
            .replace('${'+OPENID+'}', openId);
}




