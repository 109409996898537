import axios from "axios";
import { ElMessage } from "element-plus";
const service = axios.create({
  baseURL: (import.meta.env.VITE_ENV === 'development' ? '' : window.BaseConfig.baseAPIUrl) + window.BaseConfig.serviceWz,
  timeout: 5000,
});

const RESHEEADER = globalConfig.requestHeader;

// 前端公用request拦截器
service.interceptors.request.use(
  (request) => {
    const token = storeUtils.getToken();
    const openId = storeUtils.getOpenId();
    if (
      token &&
      openId &&
      !(request.params && request.params[RESHEEADER.TOKEN])
    ) {
      request.headers[RESHEEADER.TOKEN] = token;
      request.headers[RESHEEADER.OPENID] = openId;
    }
    return request;
  },
  (error) => {
    // 发生异常时执行
    console.log(error); // for debug
  }
);

// 前端公用respone拦截器
service.interceptors.response.use(
  (response) => {
    let newToken = response.headers[RESHEEADER.TOKEN.toLowerCase()];
    // 更新token
    if (newToken) {
      storeUtils.setToken(newToken);
    }
    if (response) {
      if (response.data.code == "0") {
        return response.data;
      } else {
        ElMessage({
          grouping: true,
          message: response.data.msg,
          type: "error",
        });
        return response.data;
      }
    } else {
      return response.data;
    }
  },
  (error) => {
    ElMessage({
      message: error.message,
      type: "error",
      grouping: true,
      duration: 2 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
