export const REGEX =  {
  // 手机号校验
  REGEX_PHONE: /^1\d{10}$/,
  // IP地址校验
  REGEX_IP: /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])(\:[1-9][0-9]*)?$/,
  // 特殊符号校验
  REGEX_SPECIAL_CHAR: /[`~%!@#^=''?~！$￥……&——‘”“'？*()（），,。.、]/,
  // 字母数字斜杠‘/’
  REGEX_CODE: /^[a-zA-Z0-9\/]+$/,
  // 变量命名规范
  REGEX_PARAM_NAME: /^([a-zA-Z]|\$|_)[a-zA-Z$0-9_]*$/,
  // 分页大小选项
  PAGE_SIZE: [20, 25, 30, 50, 100],
  REGEX_EMAIL: /^(\w)+(\.\w+)*@(\w)+((\.\w{2,3}){1,3})$/, // 邮箱格式
  REGEX_PSSWORD: /^[0-9A-Za-z`~%!@#^=''?~！$￥……&——‘”“'？*()（），,。.、]*$/, // 密码格式
  REGEX_NUMBER: /^[0-9]*$/, // 纯数字
  REGEX_NUMBER_LETTER: /^[A-Za-z0-9]+$/, // 数字或字母
  REGEX_NUMBER_Character_20: /^[A-Za-z0-9]{1,20}$/, // 20以内（包括20）的英文或数字
  REGEX_IDENTITY_NUMBER: /^[a-zA-Z][a-zA-Z0-9_]*$/, // 以英文字母开头，只能包含英文字母、数字、下划线
  REGEX_HTTP_URL: /^http:\/\/[A-Za-z0-9]+\.[A-Za-z0-9]+[\/=\?%\-&_~`@[\]\’:+!]*([^<>\"\"])*$/, // http路径正则
  REGEX_HTTP_URL_DOMAIN: /^http:\/\/[A-Za-z0-9]+\.[A-Za-z0-9]+[\/=\?%\-&_~`@[\]\’:+!]*([^<>\"\"])*$/, // http路径正则
  REGEX_PHONE_MIDDLE_6: /(\d{3})\d{6}(\d{2})/, // 手机号中间6位
  REGEX_EMAIL_MIDDLE: /(\w?)(\w+)(\w)(@\w+\.[a-z]+(\.[a-z]+)?)/, // 邮箱只保留前后各一位
  REGEX_ID_CARD: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/ // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
}

export const EMAIL_REGEX_ADRESS_MAP= {
  "^[a-zA-Z0-9_-]+@163\\.com$": "https://mail.163.com",
  "^[a-zA-Z0-9_-]+@126\\.com$": "https://www.126.com",
  "^[a-zA-Z0-9_-]+@yeah\\.net$": "https://www.yeah.net",
  "^[a-zA-Z0-9_-]+@qq\\.com$": "https://mail.qq.com",
  "^[a-zA-Z0-9_-]+@foxmail\\.com$": "https://www.foxmail.com",
  "^[a-zA-Z0-9_-]+@sina\\.(com|cn)$": "https://mail.sina.com.cn",
  "^[a-zA-Z0-9_-]+@sohu\\.com$": "https://mail.sohu.com",
  "^[a-zA-Z0-9_-]+@aliyun\\.com$": "https://mail.aliyun.com",
  "^[a-zA-Z0-9_-]+@139\\.com$": "https://mail.10086.cn",
  "^[a-zA-Z0-9_-]+@21cn\\.com$": "https://mail.21cn.com",
  "^[a-zA-Z0-9_-]+@tom\\.com$": "https://mail.tom.com",
  "^[a-zA-Z0-9_-]+@189\\.cn$": "https://mail.189.cn",
  "^[a-zA-Z0-9.+_-]+@gmail\\.com$": "https://mail.google.com",
  "^[a-zA-Z0-9.+_-]+@(outlook|hotmail)\\.com$": "https://outlook.live.com",
  "^[a-zA-Z0-9_-]+@yahoo\\.(com|co\\.[a-z]{2})$": "https://mail.yahoo.com",
  "^[a-zA-Z0-9_-]+@icloud\\.com$": "https://www.icloud.com/mail",
  "^[a-zA-Z0-9.+_-]+@proton(mail)?\\.(com|me)$": "https://mail.proton.me",
  "^[a-zA-Z0-9_-]+@zoho\\.(com|cn)$": "https://www.zoho.com/mail"
}
