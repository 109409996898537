import { useAppStore } from "@/stores/index.ts";
import { replacePlaceholders } from "@/utils";
export function useHooks() {
  const store = useAppStore();
  function routeToWorkspace() {
    const useInfo = store.userInfo;
    if(!useInfo){
      return;
    }
    const url = replacePlaceholders(globalConfig.WORKSPACE_URL,useInfo);
    window.location.href = url;
  }
  return {
    routeToWorkspace
  }
}
